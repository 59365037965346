import React, { useState } from 'react'
import "./OrderList.css"
import { Button, Modal } from 'react-bootstrap'
import { useUpdateOrderConfirmationMutation } from '../../store/api/marketApi'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const OrderList = ({ data, type }) => {
    const [orderConfirmation] = useUpdateOrderConfirmationMutation()
    const navigate = useNavigate()
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    // mark dispatch by seller
    const [sellerModal, setSellerModal] = useState(false)
    const handleProductDispatch = () => {
        let prodData = {
            orderId: data.order_id,
            userId: userSliceData._id,
            isSeller: true
        }
        orderConfirmation(prodData).then((res) => {
            console.log(res)
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error(res?.error?.data?.message)
            }
        })
        setSellerModal(false)
    }

    // mark receive by buyer
    const [buyerModal, setBuyerModal] = useState(false)
    const handleProductReceive = () => {
        let prodData = {
            orderId: data.order_id,
            userId: userSliceData._id,
            isSeller: false
        }
        orderConfirmation(prodData).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            } else {
                toast.error(res?.error?.data?.message)
            }
        })
        setBuyerModal(false)
    }

    if (type === "sell") {
        return (
            <div className='order_item' style={{ border: "1px solid var(--border-color)" }}>
                <div className='d-flex'>
                    <img style={{ width: '150px', height: '150px' }} src={data?.product?.cover_image[0]?.image_url} alt="" />
                    <div>
                        <h5 className='ml-4' style={{ cursor: 'pointer' }} onClick={() => navigate(`/marketplace/${data.product._id}`)}>{data?.product?.product_name}</h5>
                        <div className='ml-4'>Buyer - {data?.buyer?.fullName}</div>
                        <div className='ml-4'>Payment Status - {data?.transaction?.status}</div>
                        <div className='ml-4 fw-bold' style={{ color: 'var(--bs-primary)', display: 'flex' }}>
                            Product Dispatched - {data?.seller_confirm === false ? (
                                <div style={{ display: 'flex', width: '60px', justifyContent: 'space-between' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => setSellerModal(true)}>YES</div>
                                    <div style={{ cursor: 'pointer' }}>NO</div>
                                </div>
                            ) : (
                                <div>YES</div>
                            )}
                        </div>
                        {
                            data?.buyer_confirm === true ? (
                                <div className='ml-4'>Your product is delivered, marked by the buyer</div>
                            ) : data?.buyer_confirm === false && data?.seller_confirm === true ? (
                                <div className='ml-4'>Product is yet to deliver</div>
                            ) : (
                                <div className='ml-4'>Product is yet to dispatch</div>
                            )
                        }
                    </div>
                </div>
                {/* CONFIRM DISPATCH MODAL */}
                <Modal centered size="sm" show={sellerModal} onHide={() => setSellerModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Have you dispatched the product?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setSellerModal(false)} className="mx-2">
                                    No
                                </Button>
                                <Button variant="danger" onClick={handleProductDispatch} className="mx-2">
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    } else {
        return (
            <div className='order_item' style={{ border: "1px solid var(--border-color)" }}>
                <div className='d-flex'>
                    <img style={{ width: '150px', height: '150px' }} src={data?.product?.cover_image[0]?.image_url} alt="" />
                    <div>
                        <h5 className='ml-4' style={{ cursor: 'pointer' }} onClick={() => navigate(`/marketplace/${data.product._id}`)}>{data?.product?.product_name}</h5>
                        <div className='ml-4'>Seller - {data?.seller?.fullName}</div>
                        <div className='ml-4'>Payment Status - {data?.transaction?.status}</div>
                        {
                            data?.seller_confirm === true ? (
                                <div className='ml-4'>Your product is dispatched by the seller</div>
                            ) : (
                                <div className='ml-4'>Your product is yet to dispatch</div>
                            )
                        }
                        {data?.seller_confirm === true &&
                            <div className='ml-4 fw-bold' style={{ color: 'var(--bs-primary)', display: 'flex' }}>
                                Product Received - {data?.buyer_confirm === false ? (
                                    <div style={{ display: 'flex', width: '60px', justifyContent: 'space-between' }}>
                                        <div style={{ cursor: 'pointer' }} onClick={() => setBuyerModal(true)} >YES</div>
                                        <div style={{ cursor: 'pointer' }}>NO</div>
                                    </div>
                                ) : (
                                    <div>YES</div>
                                )}
                            </div>
                        }
                    </div>
                </div>
                {/* CONFIRM RECEIVE MODAL */}
                <Modal centered size="sm" show={buyerModal} onHide={() => setBuyerModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Have you received the product?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setBuyerModal(false)} className="mx-2">
                                    No
                                </Button>
                                <Button variant="danger" onClick={handleProductReceive} className="mx-2">
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }

}

export default OrderList
