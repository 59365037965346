import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/logo.svg";
import { RiChat3Line } from "react-icons/ri";
import { RiWalletLine } from "react-icons/ri";
import "./header.css";
import { setOffCanvas } from "../../../store/slice/headerSlice.js";
import SettingSidePanel from "../../settingSidePanel/settingSidePanel.jsx";
import { logOut } from "../../../store/slice/userSlice.js";
import { useSignoutUserMutation } from "../../../store/api/authApi.js";
import { defaultUserDp } from "../../components.jsx";
import { IoNotificationsOutline } from "react-icons/io5";
import { useWalletHistoryQuery } from "../../../store/api/walletApi.js";
import { Tooltip } from "antd";
import { TbSearch } from "react-icons/tb";
import { MdOutlineSupportAgent } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { VscNote } from "react-icons/vsc";
import { PiSuitcase } from "react-icons/pi";
import { BsShopWindow } from "react-icons/bs";
import { BiUserPlus } from "react-icons/bi";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { socket } from "../../../methods/Socket.js";

const HeaderDesktop = () => {
  const { pathname } = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signout] = useSignoutUserMutation();
  const wallet = useWalletHistoryQuery()
  const walletHistory = wallet?.data?.data;

  const { userSliceData, userAuthToken } = useSelector((store) => {
    return store.userSlice;
  });
  let headerHeight;

  // TOGGLE OFFCANVAS
  const handleShow = () => {
    dispatch(setOffCanvas(true));
  };

  const handleScroll = () => {
    const offset = window.scrollY;
    if (document.getElementsByClassName("topbar").length > 0) {
      headerHeight = document.getElementsByClassName("topbar")[0].offsetHeight;
      if (offset > headerHeight) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
  };

  // MAIN MENU TOGGLE
  const toogleMainMenu = () => {
    if (toggleMenu) {
      setToggleMenu(false);
    } else {
      setToggleMenu(true);
    }
  };

  const logout = () => {
    dispatch(logOut());
    signout(userAuthToken);
    navigate("/login")
    window.location.reload()
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setToggleMenu(false)
    socket.on("receiveNotificationCount", (data) => {
      if (data) console.log("totalcount", data);
    });
  }, [pathname]);



  return (
    <>
      <div className={scrolled ? "topbar fixed" : "topbar"}>
        <div className="header-main-container">
          <div className="logo">
            <Link to={"/"}>
              <img src={logo} alt="logo/Ubuntu" />
            </Link>
          </div>

          <div className="top-area">
            {/* main menu toggle */}
            {/* <div className="main-menu" onClick={toogleMainMenu}>
              <span>
                <img src={MenuIcon} height={30} width={30} alt="..." />
              </span>
            </div> */}
            {/* HEADER LINKS */}
            <ul className="setting-area">
              {/* new request */}
              <li>
                <Link to={`profile/${userSliceData._id}/connections`}>
                  <BiUserPlus />
                  <span>Connections</span>
                </Link>
              </li>
              {/* groups */}
              <li>
                <Link to={`profile/${userSliceData._id}/groups`}>
                  <HiOutlineUserGroup />
                  <span>Groups</span>
                </Link>
              </li>
              {/* events */}
              <li>
                <Link to={`profile/${userSliceData._id}/events`}>
                  <HiOutlineCalendarDays />
                  <span>Events</span>
                </Link>
              </li>
              {/* pages */}
              <li>
                <Link to={`profile/${userSliceData._id}/pages`}>
                  <VscNote />
                  <span>Pages</span>
                </Link>
              </li>
              {/* community */}
              <li>
                <Link to={`/community`}>
                  <LiaHandsHelpingSolid />
                  <span>Community</span>
                </Link>
              </li>
              {/* employment */}
              <li>
                <Link to={`/employment`}>
                  <PiSuitcase />
                  <span>Jobs</span>
                </Link>
              </li>
              {/* market place */}
              <li>
                <Link to={`/marketplace`}>
                  <BsShopWindow />
                  <span>Marketplace</span>
                </Link>
              </li>
            </ul>

            <ul className="setting-area">
              {/* wallet */}
              <li>
                <Tooltip placement="bottom" title={"Wallet"} color="#1f2b74">
                  <Link to={`/wallet`}>
                    <RiWalletLine />
                  </Link>
                </Tooltip>
                R{walletHistory?.totalBalance}
              </li>
            </ul>

            {/* whether widget */}
            {/* <Weather /> */}

            {/* right buttons */}
            <span className="main-menu" onClick={() => navigate("/search")}>
              <TbSearch />
            </span>

            <span className="main-menu style2">
              <Link to={`/chat`}>
                <RiChat3Line />
                <span className="number_badge">2</span>
              </Link>
            </span>

            <span className="main-menu style2" onClick={() => navigate("/notifications")}>
              <IoNotificationsOutline />
              <span className="number_badge">2</span>
            </span>
            {/* <span className="main-menu" onClick={() => navigate("/search")}>
              <MdOutlineSupportAgent />
            </span>

            <span className="main-menu" onClick={handleShow}>
              <FiSettings />
            </span> */}

            {/* user info */}
            <div className="user-img">
              <img
                src={userSliceData?.profile_picture?.length > 0 ? userSliceData?.profile_picture[0]?.image_url : defaultUserDp}
                width={'45px'}
                alt=""
              />
              <div className="user-setting">
                <Link to={`profile/${userSliceData._id}/timeline`} className="user_top">
                  <h5 className="author-name">{userSliceData.fullName}</h5>
                </Link>
                <ul className="log-out">
                  <li>
                    <Link to={`/profile/${userSliceData._id}/timeline`}>
                      <i className="ti-user" />
                      View profile
                    </Link>
                  </li>
                  <li>
                    <Link to={"/activity"}>
                      <i className="ti-target" />
                      Activity log
                    </Link>
                  </li>
                  <li>
                    <Link to={"/savedPost"}>
                      <i className="ti-power-off" />
                      Saved Posts
                    </Link>
                  </li>
                  <li>
                    <Link to={"/employment/saved"}>
                      <i className="ti-power-off" />
                      Saved Jobs
                    </Link>
                  </li>
                  <li>
                    <Link to={"/marketplace/saved"}>
                      <i className="ti-power-off" />
                      Saved Products
                    </Link>
                  </li>
                  <li>
                    <Link to={"/profile/blockedConnection"}>
                      <i className="ti-power-off" />
                      Blocked Users
                    </Link>
                  </li>
                  <li>
                    <Link to={"/setting"}>
                      <MdOutlineSupportAgent />
                      Customer Support
                    </Link>
                  </li>
                  <li>
                    {/* <Link to={"/setting"}>
                      <i className="ti-settings" />
                      Account setting
                    </Link> */}
                  </li>
                  <li>
                    <Link to={""} onClick={logout}>
                      <i className="ti-power-off" />
                      Log out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SettingSidePanel />
    </>
  );
};

export default HeaderDesktop;
