import React, { useEffect, useState } from 'react'
import ProfileLayout from '../../ProfileLayout';
import { DashboardMenuLink, FilterWrapper, UserCard } from '../../../../components/components';
import { useAllCountListsQuery, useGetSuggestedUserQuery } from '../../../../store/api/userApi';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Pagination } from 'antd';


const SuggestedCon = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
  const [search, setSearch] = useState(searchParams.get("search") || "")
  const [sort, setSort] = useState(searchParams.get("sort") || "all")

  const paramObj = {
    pageNo: pageNo,
    searchVal: search,
    sortVal: sort
  }

  const paginationPageChange = (page) => {
    setPageNo(Number(page))
  }

  //get suggested connection list
  const suggestedConnection = useGetSuggestedUserQuery(paramObj)
  const suggestedConnectionData = suggestedConnection?.data?.suggestedconnection;
  const countData = useAllCountListsQuery()

  useEffect(() => {
    let params = { pageNo };
    if (search) {
      params.search = search;
    }
    if (sort) {
      params.sort = sort;
    }
    setSearchParams(params);
  }, [pageNo, search, sort]);

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  });
  
  return (
    <ProfileLayout>
      <FilterWrapper>
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="DashboardMenuLinks">
              <DashboardMenuLink url={`/profile/${userSliceData._id}/connections`} title="Connections" count={countData.isSuccess ? countData.data.data.total_friends : 0} className={'test'} />
              <DashboardMenuLink url={`/profile/${userSliceData._id}/connections/new`} title="New Request" count={countData.isSuccess ? countData.data.data.total_pending_requests : 0} />
              <DashboardMenuLink url={`/profile/${userSliceData._id}/connections/suggested`} title="Suggested Connections" count={countData.isSuccess ? countData.data.data.total_suggested_connection : 0} />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="gx-2 justify-content-md-end row">
              <div className="col-lg-7 col-md-7 col-8">
                <form method="post">
                  <input
                    type="text"
                    placeholder="Search.."
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                    value={searchParams.get("search") || search}
                  />
                </form>
              </div>
              <div className="col-lg-4 col-md-4 col-4">
                <div className="select-options">
                  <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                    <option value={"all"}>See All</option>
                    <option value={"AtoZ"}>A to Z</option>
                    <option value={"ZtoA"}>Z to A</option>
                    <option value={"newest"}>Newest</option>
                    <option value={"oldest"}>Oldest</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FilterWrapper>
      <div className=''>
        <div className='row g-md-3 g-2'>
          {
            suggestedConnection.isLoading && (
              <div className="col-12">
                <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
              </div>
            )
          }
          {
            suggestedConnection.isError && (
              <div className="col-12">
                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong!!</h5>
              </div>
            )
          }
          {suggestedConnectionData?.map((elem, index) => {
            return (
              <div className='col-lg-2 col-md-4 col-6' key={index}>
                <UserCard data={elem} type="suggested" />
              </div>
            )
          })}
          {suggestedConnectionData?.length === 0 && <div className="col-12">
            <h5 className="text-center mb-0 py-5 fs_18">No Suggested Connection !!!</h5>
          </div>}
        </div>
        {suggestedConnection?.data?.totalsuggestedQuery > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={pageNo} total={suggestedConnection?.data?.totalsuggestedQuery} onChange={paginationPageChange} defaultPageSize={12} />}
      </div>
    </ProfileLayout>
  )
}

export default SuggestedCon