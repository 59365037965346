import React, { useEffect, useState } from 'react'
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../components/components';
import ProfileLayout from '../ProfileLayout';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetAlbumOthersQuery, useGetAlbumQuery } from '../../../store/api/userApi';

const Videos = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const { userId } = useParams()
    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: userId,
        meta_type: "User",
        meta_data: userId
    }

    const AlbumDataOthers = useGetAlbumOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumQuery(userSliceData._id)
    const AllImages = userId === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const AllImagesData = AllImages?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    let [data, setData] = useState([])

    useEffect(() => {
        if (AllImagesData != undefined) {
            let AllImegesList = AllImagesData?.filter((ele) => ele?.file[0]?.mime_type.slice(0, 5) === "video")
            setData(AllImegesList)
        }
    }, [])
    return (
        <ProfileLayout>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userId}/media`} title="Images" noCount end />
                            <DashboardMenuLink url={`/profile/${userId}/media/video`} title="Videos" noCount end />
                        </div>
                    </div>
                </div>
            </FilterWrapper>


            {data.length === 0 ?
                <div className="d-flex justify-content-center">
                    No videos found !! </div> :
                <div className="central-meta">
                    <div className="row gx-4">
                        {data.map((elem, index) => {
                            return (
                                <div className="col-lg-3 col-md-3 col-6" key={index}>
                                    <MediaCard key={index} type={'video'} ele={elem} />
                                </div>
                            );
                        })}
                    </div>
                </div>}
        </ProfileLayout>
    )
}

export default Videos