import React from 'react'
import ProfileLayout from '../../ProfileLayout';
import { DashboardMenuLink, FilterWrapper } from '../../../../components/components';
import { Link, useParams } from 'react-router-dom';
import { useGetAlbumOthersQuery, useGetAlbumQuery } from '../../../../store/api/userApi';
import './images.css';
import { useSelector } from 'react-redux';
import { CiImageOn } from 'react-icons/ci';
import { IoVideocamOutline } from 'react-icons/io5';

const Images = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const { userId } = useParams()

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: userId,
        meta_type: "User",
        meta_data: userId
    }
    const AlbumDataOthers = useGetAlbumOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumQuery(userSliceData._id)
    const Images = userId === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const CoverImagesData = Images?.filter(function (el) {
        return el.name === "cover_photo";
    })[0]?.gallery;

    const AllImagesData = Images?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    const ProfileImagesData = Images?.filter(function (el) {
        return el.name === "profile_picture";
    })[0]?.gallery;

// console.log("imgg",AllImagesData)
let AllImegesList=AllImagesData?.filter((ele)=>ele.file[0].mime_type.slice(0,5) ===   "image")


    return (
        <ProfileLayout>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media`} title="Images" noCount />
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media/video`} title="Videos" noCount />
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className='row gx-4'>
                    <div className='col-md-2'>
                        <Link to={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media/profile_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Profile Picture</h5>
                                    <p>{ProfileImagesData?.length > 0 ? ProfileImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media/cover_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>Cover Picture</h5>
                                    <p>{CoverImagesData?.length > 0 ? CoverImagesData?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='col-md-2'>
                        <Link to={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media/all_picture`}>
                            <div className="folder">
                                <div className="top"></div>
                                <div className="head">
                                    <div className="icon">🖼️</div>
                                </div>
                                <div className="info">
                                    <h5>All Picture</h5>
                                    <p>{AllImegesList?.length > 0 ? AllImegesList?.length : 0} items</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </ProfileLayout>
    )
}

export default Images