import React, { useReducer, useState } from 'react'
import { Link } from 'react-router-dom'
import './commentItem.css';
import { Image, defaultUserDp } from '../../../components';
import { BsThreeDots } from "react-icons/bs";
import { Dropdown } from "antd";
import { useDeleteCommentMutation, useEditCommentMutation, useReplyCommentMutation } from '../../../../store/api/commentApi';
import toast from 'react-hot-toast';
import EpochTimeToDateTime from '../../../../methods/EpochTimeToDateTime';
import TextArea from 'antd/es/input/TextArea';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlineSend } from "react-icons/ai";
import { useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import { useGetConnectionByIdQuery } from '../../../../store/api/userApi';
import { BindToGenLink } from '../../../../methods/BindToGenLink';
import CommentLavelTwo from './commentLevelTwo/CommentLavelTwo';
import Reacts from '../../../utils/reacts/Reacts';
import ReactOnComment from '../../../utils/reacts/ReactOnComment';
import { useGetReactsOnCommentQuery } from '../../../../store/api/reactApi';

const CommentItem = ({ data, postIndex }) => {
    const [showDeleteModal, setDeleteModal] = useState(false);
    //FOR REPLY COMMENT
    const [childFirstInput, setChildFirstInput] = useState(false)
    const [replyText, setReplyText] = useState("")
    const editCommentInitState = {
        reply: false,
        editMode: false,
        text: data.comment.comment_text,
        images: data.comment.comment_images,
        videos: data.comment.comment_images,
    };
    const CommentReducer = (state, action) => {
        switch (action.type) {
            case "SET_REPLY":
                return { ...state, reply: action.payload };
            case "SET_EDIT":
                return { ...state, editMode: action.payload };
            case "SET_TEXT":
                return { ...state, text: action.payload };
            case "SET_IMAGES":
                return { ...state, images: action.payload };
            case "RESET_REDUCER":
                return {
                    preview: false,
                    text: "",
                    images: [],
                    videos: [],
                };
            default:
                return state;
        }
    };
    const [editCommentState, commentDispatch] = useReducer(CommentReducer, editCommentInitState);
    const [deleteCommentItem] = useDeleteCommentMutation()
    const [editCommentItem] = useEditCommentMutation()
    const [replyComment] = useReplyCommentMutation()
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    })

    // SHOWING THE INPUT FIELD
    const showEditComment = () => {
        commentDispatch({ type: "SET_EDIT", payload: true })
    }

    // HANDING COMMENT TEXTAREA
    const handleComment = (value) => {
        commentDispatch({ type: "SET_TEXT", payload: value });
    };

    // UPDATEING COMMENT ON SUBMIT
    const updateComment = () => {
        const arg = {
            postIndex,
            comment_id: data?.comment?._id,
            comment_text: editCommentState.text,
            comment_images: editCommentState.images
        }
        editCommentItem(arg).then((res) => {
            commentDispatch({ type: "SET_EDIT", payload: false })
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
            }
        })
    }

    // DELETING COMMENT
    const deleteComment = () => {
        setDeleteModal(true)
        // setChildFirstInput(!childFirstInput)
    }

    const deleteCommentModal = () => {
        const arg = {
            postIndex,
            post_id: data?.comment?.post,
            comment_id: data?.comment?._id
        }
        deleteCommentItem(arg).then((res) => {
            if (res?.data?.error === 0) {
                toast.success(res?.data?.message)
                setDeleteModal(false)
            }
        })
    }

    const getReacts = useGetReactsOnCommentQuery({
        comment_id: data?.comment?._id,
        page: 1,
        per_page: 10000000,
    })
    const reactListOnComment = getReacts?.data?.data;

    // COMMENT DROPDOWN ITEMS LIST
    const items = userSliceData._id === data?.comment?.user?._id ? (
        [
            {
                label: <div onClick={() => showEditComment()}>Edit comment</div>,
                key: "0",
            },
            {
                label: <div onClick={() => deleteComment()}>Delete comment</div>,
                key: "1",
            }
        ]
    ) : (
        [
            {
                label: <div onClick={() => { }}>Report</div>,
                key: "2",
            }
        ]
    )

    // REPLY OF A EXISTING COMMENT
    const replyCommentFunc = () => {
        let obj = {
            parent_id: data?.comment?._id,
            post_id: data?.comment?.post,
            comment_text: replyText
        }
        replyComment(obj).then((res) => {
            setReplyText("")
            setChildFirstInput(!childFirstInput)
        })
    }

    const MyConnection = useGetConnectionByIdQuery({
        id: userSliceData._id
    });
    const formattedUser = MyConnection?.data?.connections?.map(({ _id, fullName }) => ({ id: _id, display: fullName }));

    const displayTransform = (id, display) => {
        return `@${display}`;
    }
    
    // when user is unauthenticated
    if (!userSliceData?._id) {
        return (
            <>
                <div className="post_comment_item">
                    {/* PARENT COMMENT*/}
                    <div className="parent_comment comment_item">
                        <div className='comment_user_info'>
                            <Image src={data?.comment?.user.profile_picture[0].image_url} alt="..." />
                            <div>
                                <Link to={`/profile/${data?.comment?.user?._id}/timeline`}>{data?.comment?.user.fullName}</Link>
                                <div className='createdAt fs_12'>{EpochTimeToDateTime(data.createdAt).date} at {EpochTimeToDateTime(data.createdAt).time}</div>
                            </div>
                            {/* <div className='comment_options'>
                                <Dropdown menu={{ items }} placement="bottomRight" trigger={["click"]}>
                                    <BsThreeDots />
                                </Dropdown>
                            </div> */}
                        </div>
                        {editCommentState.editMode === false ? (
                            <>
                                {/*PARENT COMMENT*/}
                                <p className='commentText' dangerouslySetInnerHTML={{ __html: BindToGenLink(data?.comment?.comment_text) }}></p>
                            </>
                        ) : (
                            <>
                                {/* EDIT COMMENT */}
                                <div className="write_comment mt-2 mb-2">
                                    <MentionsInput placeholder='Write Comment here...' value={editCommentState.text} onChange={(e) => handleComment(e.target.value)} className='mentionList' style={{ height: '40px' }} >
                                        <Mention trigger="@" data={formattedUser} displayTransform={displayTransform} />
                                    </MentionsInput>
                                    <Button onClick={updateComment}><AiOutlineSend /></Button>
                                </div>
                            </>
                        )}
                        <div className="comment_actions">
                            <ReactOnComment commentId={data?.comment?._id} reactListOnComment={reactListOnComment} />
                            {/* <div className='comment__action like'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-thumbs-up">
                                        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
                                    </svg>
                                </span>
                            </div> */}
                            {/* <div className='comment__action reply' onClick={() => commentDispatch({ type: "SET_REPLY", payload: !editCommentState.reply })}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-right-down">
                                        <polyline points="10 15 15 20 20 15"></polyline>
                                        <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                    </svg>
                                </span>
                            </div> */}
                        </div>

                        {/* REPLY ON COMMENT */}
                        {editCommentState.reply && (
                            <div className="write_comment mt-2">
                                <MentionsInput placeholder='Write Comment here...' value={replyText} onChange={(e) => setReplyText(e.target.value)} className='mentionList' style={{ minHeight: '40px' }} >
                                    <Mention trigger="@" data={formattedUser} displayTransform={displayTransform} />
                                </MentionsInput>
                                <Button onClick={replyCommentFunc}><AiOutlineSend /></Button>
                            </div>
                        )}
                    </div>
                    {/* CHILD COMMENT */}
                    <div className='childCommentList'>
                        {data?.children?.length > 0 && data.children.map((elem, index) => {
                            return <CommentLavelTwo key={index} data={elem} reactListOnComment={reactListOnComment} />
                        })}
                    </div>
                </div>
                {/* CONFIRM DELETE MODAL */}
                <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                    <Modal.Body>
                        <div className="text-center">
                            <h5 className="mb-3">Are you sure, you want to delete the comment?</h5>
                            <div className="text-center">
                                <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                    Cancel
                                </Button>
                                <Button variant="danger" onClick={deleteCommentModal} className="mx-2">
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

    return (
        <>
            <div className="post_comment_item">
                {/* PARENT COMMENT*/}
                <div className="parent_comment comment_item">
                    <div className='comment_user_info'>
                        <Image src={data?.comment?.user.profile_picture[0].image_url} alt="..." />
                        <div>
                            <Link to={`/profile/${data?.comment?.user?._id}/timeline`}>{data?.comment?.user.fullName}</Link>
                            <div className='createdAt fs_12'>{EpochTimeToDateTime(data.createdAt).date} at {EpochTimeToDateTime(data.createdAt).time}</div>
                        </div>
                        <div className='comment_options'>
                            <Dropdown menu={{ items }} placement="bottomRight" trigger={["click"]}>
                                <BsThreeDots />
                            </Dropdown>
                        </div>
                    </div>
                    {editCommentState.editMode === false ? (
                        <>
                            {/*PARENT COMMENT*/}
                            <p className='commentText' dangerouslySetInnerHTML={{ __html: BindToGenLink(data?.comment?.comment_text) }}></p>
                        </>
                    ) : (
                        <>
                            {/* EDIT COMMENT */}
                            <div className="write_comment mt-2 mb-2">
                                <MentionsInput placeholder='Write Comment here...' value={editCommentState.text} onChange={(e) => handleComment(e.target.value)} className='mentionList' style={{ height: '40px' }} >
                                    <Mention trigger="@" data={formattedUser} displayTransform={displayTransform} />
                                </MentionsInput>
                                <Button onClick={updateComment}><AiOutlineSend /></Button>
                            </div>
                        </>
                    )}
                    <div className="comment_actions">
                        <ReactOnComment commentId={data?.comment?._id} reactListOnComment={reactListOnComment} />
                        <div className='comment__action reply' onClick={() => commentDispatch({ type: "SET_REPLY", payload: !editCommentState.reply })}>
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-corner-right-down">
                                    <polyline points="10 15 15 20 20 15"></polyline>
                                    <path d="M4 4h7a4 4 0 0 1 4 4v12"></path>
                                </svg>
                            </span>
                        </div>
                    </div>

                    {/* REPLY ON COMMENT */}
                    {editCommentState.reply && (
                        <div className="write_comment mt-2">
                            <MentionsInput placeholder='Write Comment here...' value={replyText} onChange={(e) => setReplyText(e.target.value)} className='mentionList' style={{ minHeight: '40px' }} >
                                <Mention trigger="@" data={formattedUser} displayTransform={displayTransform} />
                            </MentionsInput>
                            <Button onClick={replyCommentFunc}><AiOutlineSend /></Button>
                        </div>
                    )}
                </div>
                {/* CHILD COMMENT */}
                <div className='childCommentList'>
                    {data?.children?.length > 0 && data.children.map((elem, index) => {
                        return <CommentLavelTwo key={index} data={elem} reactListOnComment={reactListOnComment} />
                    })}
                </div>
            </div>
            {/* CONFIRM DELETE MODAL */}
            <Modal centered size="sm" show={showDeleteModal} onHide={() => setDeleteModal(false)}>
                <Modal.Body>
                    <div className="text-center">
                        <h5 className="mb-3">Are you sure, you want to delete the comment?</h5>
                        <div className="text-center">
                            <Button variant="secondary" onClick={() => setDeleteModal(false)} className="mx-2">
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={deleteCommentModal} className="mx-2">
                                Delete
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CommentItem;