import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const eventApi = createApi({
    reducerPath: "eventApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        // set access token to headers
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["event"],
    endpoints: (builder) => ({
        // ALL TYPE OF EVENT LISTING
        // GET MY ALL CREAETD EVENTS
        myEvents: builder.query({
            query: (data) => ({
                url: "api/events/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 7,
                    role: "admin",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                },
            }),
            providesTags: ["event"],
        }),
        // GET ALL SUGGESTED EVENTS
        suggestedEvents: builder.query({
            query: (data) => ({
                url: "api/events/suggested",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["event"],
        }),
        //pending invitation list from pages
        getPendingInvitationList: builder.query({
            query: (data) => ({
                url: "api/events/list",
                method: "GET",
                params: {
                    page: data.pageNo,
                    per_page: 8,
                    role: "member",
                    join_type: "invited",
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["event"],
        }),
        // GET ALL JOINED EVENTS
        joinedEvents: builder.query({
            query: (data) => ({
                url: "api/events/list",
                method: "GET",
                params: {
                    role: "member",
                    status: 'active',
                    page: data.pageNo || 1,
                    per_page: 8,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all"
                },
            }),
            providesTags: ["event"],
        }),

        // DETAILS EVENT MEMBER LISTING
        // GET EVENT JOINED GUEST
        guest: builder.query({
            query: (data) => ({
                url: "api/events/details",
                method: "GET",
                params: {
                    event_id: data.event_id,
                    member_page: data.pageNo,
                    member_per_page: 100,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    role: 'member',
                    status: 'active',
                },
            }),
            providesTags: ["event"],
        }),
        // PENDING GUEST IN EVENT
        pendingGuest: builder.query({
            query: (data) => ({
                url: "api/events/details",
                method: "GET",
                params: {
                    event_id: data.event_id,
                    role: "member",
                    status: 'pending',
                    page: data.pageNo,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    per_page: 100,
                    join_type: "join"
                },
            }),
            providesTags: ["event"],
        }),
        // GET SUGGESTED GUESTS LIST
        suggestedGuestList: builder.query({
            query: (data) => ({
                url: "api/events/member/suggested",
                method: "GET",
                params: {
                    event_id: data.event_id,
                    page: data.pageNo,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal || "see_all",
                    per_page: 100
                },
            }),
            providesTags: ["event"],
        }),

        // GET SINGLE EVENT
        getSingleEvent: builder.query({
            query: (id) => ({
                url: "api/events/details",
                method: "GET",
                params: {
                    event_id: id
                },
            }),
            providesTags: ["event"],
        }),
        // CREATE NEW EVENT
        createEvent: builder.mutation({
            query: (eventData) => {
                return {
                    url: "api/events/create",
                    method: "POST",
                    body: eventData,
                };
            },
            invalidatesTags: ["event"],
        }),
        // UPDATE EVENT DETAILS
        updateEvent: builder.mutation({
            query: (data) => ({
                url: "api/events/update",
                method: "PUT",
                body: data
            }),
            invalidatesTags: ["event"],
        }),
        // ACCEPT JOINED REQUEST
        acceptRequest: builder.mutation({
            query: (data) => {
                return {
                    url: "api/events/update",
                    method: "PUT",
                    body: {
                        event_id: data.event_id,
                        members: {
                            user_id: data.user_id,
                            status: "active",
                            role: "member"
                        }
                    },
                };
            },
            invalidatesTags: ["event"],
        }),
        // ACCEPT JOINED REQUEST
        removeFromEvent: builder.mutation({
            query: (data) => {
                return {
                    url: "api/events/member/add-or-remove",
                    method: "PUT",
                    body: {
                        event_id: data.event_id,
                        members: {
                            user_id: data.user_id,
                            is_remove: true
                        }
                    },
                };
            },
            invalidatesTags: ["event"],
        }),
        // JOIN OTHER EVENT
        toJoinEvent: builder.mutation({
            query: (data) => {
                return {
                    url: "api/events/member/add-or-remove",
                    method: "PUT",
                    body: {
                        event_id: data.event_id,
                        members: {
                            user_id: data.user_id,
                            status: "pending",
                            join_type: "join"
                        }
                    },
                };
            },
            invalidatesTags: ["event"],
        }),
        // LEAVE EVENT
        leaveEvent: builder.mutation({
            query: (data) => {
                return {
                    url: "api/events/member/add-or-remove",
                    method: "PUT",
                    body: {
                        event_id: data.event_id,
                        members: {
                            user_id: data.user_id,
                            is_remove: true
                        }
                    },
                };
            },
            invalidatesTags: ["event"],
        }),
        // DELETE EVENT
        deleteEvent: builder.mutation({
            query: (data) => ({
                url: "api/events/delete",
                method: "DELETE",
                body: data
            }),
            invalidatesTags: ["event"],
        }),
        // invite as member
        inviteFollower: builder.mutation({
            query: (data) => ({
                url: "api/member/invite",
                method: "PUT",
                body: {
                    meta_type: "Event",
                    meta_data: data.eventId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["event"],
        }),
        // accept pending request to join a page
        acceptPendingRequest: builder.mutation({
            query: (data) => ({
                url: "api/member/accept-invitation",
                method: "PUT",
                body: {
                    meta_type: "Event",
                    meta_data: data.eventId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["event"],
        }),
        // decline pending request to join a event
        declinePendingRequest: builder.mutation({
            query: (data) => ({
                url: "api/member/remove-invitation",
                method: "DELETE",
                body: {
                    meta_type: "Event",
                    meta_data: data.eventId,
                    members: {
                        user_id: data.userId,
                        role: "member"
                    }
                }
            }),
            invalidatesTags: ["event"],
        }),

        // other user event apis
        // GET CREATED EVENT
        getCreatedEventById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    module: "Event",
                    user_id: data.id,
                    role: "admin",
                    page: data.pageNo,
                    per_page: 7,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                }
            }),
            providesTags: ["event"],
        }),
        // GET JOINED EVENT
        getJoinedEventById: builder.query({
            query: (data) => ({
                url: "api/users/profile-details",
                method: "GET",
                params: {
                    module: "Event",
                    user_id: data.id,
                    role: "member",
                    page: data.pageNo,
                    per_page: 8,
                    search_keyword: data.searchVal,
                    sort_by: data.sortVal
                }
            }),
            providesTags: ["event"],
        }),
        // JOIN EVENT OTHER
        toJoinEventOthers: builder.mutation({
            query: (data) => {
                return {
                    url: "api/events/member/add-or-remove",
                    method: "PUT",
                    body: {
                        event_id: data.event_id,
                        members: {
                            user_id: data.user_id,
                            status: "pending",
                            join_type: "join"
                        }
                    },
                };
            },
            invalidatesTags: ["event"],
        }),
        // LEAVE EVENT OTHERS
        leaveEventOthers: builder.mutation({
            query: (data) => {
                return {
                    url: "api/events/member/add-or-remove",
                    method: "PUT",
                    body: {
                        event_id: data.event_id,
                        members: {
                            user_id: data.user_id,
                            is_remove: true
                        }
                    },
                };
            },
            invalidatesTags: ["event"],
        }),

        // Album APIs
        getAlbumEvent: builder.query({
            query: (id) => {
                return {
                    url: "api/album",
                    method: "GET",
                    params: {
                        page: 1,
                        meta_type: "Event",
                        meta_data: id
                    }
                };
            },
            providesTags: ["event"],
        }),

        getAlbumEventOthers: builder.query({
            query: (data) => {
                return {
                    url: "api/users/profile-details",
                    method: "GET",
                    params: data
                };
            },
            providesTags: ["event"],
        }),


        allCountEventLists: builder.query({
            query: () => {
                return {
                    url: `api/all/count`,
                    method: "GET",
                    params: {
                        module: "Event"
                    },
                }
            },
            providesTags: ["event"],

        }),

        allOtherEventCountLists: builder.query({
            query: (id) => {
                return {
                    url: `api/all/count`,
                    method: "GET",
                    params: {
                        module: "Event",
                        user_id: id
                    },
                }
            },
            providesTags: ["event"],



        })
    })
});

export const { useAllOtherEventCountListsQuery, useAllCountEventListsQuery, useGetAlbumEventOthersQuery, useGetAlbumEventQuery, useGetSingleEventQuery, useCreateEventMutation, useUpdateEventMutation, useMyEventsQuery, useGuestQuery, usePendingGuestQuery, useAcceptRequestMutation, useRemoveFromEventMutation, useSuggestedEventsQuery, useJoinedEventsQuery, useToJoinEventMutation, useLeaveEventMutation, useSuggestedGuestListQuery, useDeleteEventMutation, useInviteFollowerMutation, useGetPendingInvitationListQuery, useAcceptPendingRequestMutation, useDeclinePendingRequestMutation, useGetCreatedEventByIdQuery, useGetJoinedEventByIdQuery, useToJoinEventOthersMutation, useLeaveEventOthersMutation } = eventApi;