import React from "react";
import { Link } from "react-router-dom";
import TrainImg from "../../../assets/images/icon/train.png";
import PlaneImg from "../../../assets/images/icon/airplane.png";
import "./planCard.css";
import { useBuyMembershipMutation } from "../../../store/api/membershipApi";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const PlanCard = ({ data }) => {

  const { userSliceData } = useSelector((store) => {
    return store.userSlice;
  })

  const [buyMembership] = useBuyMembershipMutation()
  const clickSelectedPlan = (id) => {
    buyMembership(id).then((res) => {
      console.log(res)
      if (res?.data?.error === 0) {
        toast.success(res?.data?.message)
        localStorage.setItem("userData", JSON.stringify({ ...userSliceData, membership: data }))
      } else {

      }
    })
  };

  let dataFromLS = JSON.parse(localStorage.getItem("userData"));

  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="price-box planPriceBox">
        <span>{data.plan_name}</span>
        <div className="pricings">
          <div className="pricings-header">
            <img src={data.price > 0 ? PlaneImg : TrainImg} alt="icon" width="80" />
            {data.price > 0 && <h5>
              R.{data.price}
              <span>
                Per Year
                <br />R.{Math.ceil(data.price / 12)} per month
              </span>
            </h5>}
            {data.price === 0 && <h5>Free</h5>}
          </div>
          <div className="pricings-bottom">
            <ul className="price-features planPriceFeatures">
              {data.features.map((el, i) => (
                <li key={i}>
                  {el.accesebility === true ? (
                    <i className="fa fa-check" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-times" aria-hidden="true"></i>
                  )}
                  {el.title}
                </li>
              ))}
            </ul>
            <Link
              className={`main-btn active ${data?.selected === true ? "planBtnActive" : "planBtnNonActive"}`}
              to="#" title="" data-ripple="" onClick={() => clickSelectedPlan(data?._id)}
            >
              {data?._id === dataFromLS?.membership?._id ? "Selected" : "Select"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
