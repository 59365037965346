import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import './chatBody.css';
import ChatItem from './chatItem/ChatItem';
import { socket } from '../../../../methods/Socket';

const ChatBody = ({ className, userData }) => {
  const [chatList, setChatList] = useState([]);
  const [currPage, setCurrPage] = useState(1); // storing current page number
  const [lastPage, setLastPage] = useState(1); // storing last page number
  const chatContainerRef = useRef(null); // Ref for chat container

  const { userSliceData } = useSelector((store) => store.userSlice);
  const { userAuthToken } = useSelector((store) => store.userSlice);
  const { roomData } = useSelector((store) => store.chat);

  // Effect to reset pagination and chatList when roomData changes
  useEffect(() => {
    console.log('Room changed, resetting pagination and chat list.');
    setChatList([]);
    setLastPage(1);
    setCurrPage(1);
    localStorage.removeItem('usm_chat_messages');
  }, [roomData]);

  // Effect to fetch chat data when currPage or roomData changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data for room:', roomData, 'Page:', currPage);
        const params = { room_id: roomData, page: currPage };
        const headers = { "x-access-token": userAuthToken };
        const response = await axios.get('https://ubuntu-social-media-api.notebrains.com/api/chat/list', { params, headers });

        const newData = response?.data?.data || [];
        const storedMessages = JSON.parse(localStorage.getItem("usm_chat_messages")) || [];

        const updatedMessages = currPage === 1 ? newData : [...newData, ...storedMessages];

        localStorage.setItem("usm_chat_messages", JSON.stringify(updatedMessages));
        setChatList(updatedMessages);
        setLastPage(response?.data?.lastPage || 1);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [roomData, currPage, userAuthToken]);

  // Scroll to the bottom of the chat when new messages are added
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatList]);

  // Infinite scroll handler to load more data when scrolled to the top of the chat container
  const handleInfiniteScroll = () => {
    if (chatContainerRef.current.scrollTop === 0 && currPage < lastPage) {
      console.log('Loading more data, current page:', currPage);
      setCurrPage((prevPage) => prevPage + 1);
    }
    console.log(chatContainerRef.current.scrollTop);

  };

  // Attach scroll event listener to chat container
  useEffect(() => {
    const chatContainer = chatContainerRef.current;

    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleInfiniteScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener('scroll', handleInfiniteScroll);
      }
    };
  }, [currPage, lastPage]);

  useEffect(() => {
    socket.on("loadsingleMessage", (messages) => {
      let chat_messages = JSON.parse(localStorage.getItem("usm_chat_messages"));
      if (messages) {
        const updatedMessages = [...chat_messages, messages];
        localStorage.setItem("usm_chat_messages", JSON.stringify(updatedMessages));
        setChatList(updatedMessages);
      }
    });

    socket.on("deleveredMessage", (data) => {
      if ((data.sender !== userSliceData._id) && (data.status === "delivered")) {
        socket.emit("messageRead", {
          room_id: data.room_id,
          user_id: data.sender,
        });
      }
      socket.on("messagesReadDone", (data) => {
        let chat_messages = JSON.parse(localStorage.getItem("usm_chat_messages"));
        const updatedData = chat_messages.map((el) => {
          el.recipients.forEach(recipient => {
            if (recipient.status === "sent") {
              recipient.status = "read";
            }
          });
          return el;
        });
        localStorage.setItem("usm_chat_messages", JSON.stringify(updatedData));
        setChatList(updatedData);
      });
    });
  }, []);

  if (!userData.fullName) {
    return null;
  }

  return (
    <div id="kaka" className={`${className} p-4`} ref={chatContainerRef} style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
      <div>
        {chatList.map((message, i) => (
          <ChatItem
            key={i}
            out={userSliceData._id === message.sender}
            text
            data={message}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatBody;