import { useParams, useSearchParams } from "react-router-dom";
import {
    DashboardMenuLink,
    FilterWrapper,
    UserCard,
} from "../../../../components/components";
import GroupProfileLayout from "../../GroupProfileLayout";
import { Dropdown } from "antd";
import { Button } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useGetGroupActiveMembersQuery, useGetGroupPendingMembersQuery, useGetSingleGroupQuery, useSuggestedGroupMemberQuery } from "../../../../store/api/groupApi";
import GroupUserCard from "../../../../components/userCard/GroupUserCard";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const Pending = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
    //Get user details
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    })
    // get id from param
    const params = useParams();
    const GROUPID = params.groupId;

    // get single Event Data
    const singleGroup = useGetSingleGroupQuery(GROUPID);
    let groupWholeData = singleGroup?.data?.data;

    // params obj
    const paramsObj = {
        group_id: GROUPID,
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }
    // fetch group pending members
    const groupPendingMembers = useGetGroupPendingMembersQuery(paramsObj)
    const groupPendingMembersData = groupPendingMembers?.data?.data?.members;

    // fetch group pending members
    const groupActiveMembers = useGetGroupActiveMembersQuery({ group_id: GROUPID, pageNo: pageNo })

    const suggestedMembers = useSuggestedGroupMemberQuery({ group_id: GROUPID, pageNo: pageNo })

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (sort) {
            params.sort = sort;
        }
        setSearchParams(params);
    }, [pageNo, search, sort]);

    return (
        <GroupProfileLayout data={groupWholeData}>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink
                                url={`/group/${GROUPID}/members`}
                                title="Members"
                                count={groupActiveMembers?.data?.total_count || 0}
                            />
                            {groupWholeData?.user?._id === userSliceData._id && <DashboardMenuLink
                                url={`/group/${GROUPID}/members/pending`}
                                title="Pending Members"
                                count={groupPendingMembers?.data?.total_count || 0}
                            />}
                            {groupWholeData?.user?._id === userSliceData._id && <DashboardMenuLink
                                url={`/group/${GROUPID}/members/suggation`}
                                title="Suggested Members"
                                count={suggestedMembers?.data?.total_count || 0}
                            />}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="gx-2 justify-content-md-end row">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <form method="post">
                                    <input type="text" placeholder="Search.." className="form-control" onChange={(e) => setSearch(e.target.value)}
                                        value={searchParams.get("search") || search} />
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="select-options">
                                    <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                                        <option value={"see_all"}>See All</option>
                                        <option value={"a_to_z"}>A to Z</option>
                                        <option value={"z_to_a"}>Z to A</option>
                                        <option value={"newest"}>Newest</option>
                                        <option value={"oldest"}>Oldest</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="col-lg-1 col-md-1 col-sm-1">
                                <Dropdown
                                    menu={{ items }}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                >
                                    <Button className="filterWrapDropBtn">
                                        <BsThreeDotsVertical />
                                    </Button>
                                </Dropdown>
                            </div> */}
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className="central-meta">
                <div className="row gx-4">
                    {groupPendingMembersData?.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-3 col-6" key={index}>
                                <GroupUserCard data={elem} type={"GroupPendingMember"} profileId={index} />
                            </div>
                        );
                    })}
                    {groupPendingMembersData?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Pending Member Found !!!</h5>
                    </div>}
                </div>
            </div>
        </GroupProfileLayout>
    );
};

export default Pending;
