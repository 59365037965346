import React from 'react'
import { Button } from 'react-bootstrap'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { DashboardMenuLink, FilterWrapper, MediaCard } from '../../../../../components/components'
import ProfileLayout from '../../../ProfileLayout'
import { Dropdown } from 'antd';
import { useGetAlbumOthersQuery, useGetAlbumQuery } from '../../../../../store/api/userApi'
import { useSelector } from 'react-redux'
import { IoVideocamOutline } from 'react-icons/io5'
import { CiImageOn } from 'react-icons/ci'
import { useParams } from 'react-router-dom'

const AllPictures = () => {
    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    const { userId } = useParams()

    const AlbumParamsOthers = {
        page: 1,
        per_page: 100,
        module: "Album",
        user_id: userId,
        meta_type: "User",
        meta_data: userId
    }
    const AlbumDataOthers = useGetAlbumOthersQuery(AlbumParamsOthers)

    const AlbumData = useGetAlbumQuery(userSliceData._id)
    const AllImages = userId === userSliceData._id ? AlbumData?.data?.data : AlbumDataOthers?.data?.data;

    const AllImagesData = AllImages?.filter(function (el) {
        return el.name === "uploads";
    })[0]?.gallery;

    console.log("all img",AllImagesData)

    // let AllImegesList=AllImagesData?.filter((ele)=>ele.file[0].mime_type=="video/mp4")
    let AllImegesList=AllImagesData?.filter((ele)=>ele.file[0].mime_type.slice(0,5) ===   "image")

 



    if (AlbumData.isLoading) {
        return (
            <>
                <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                    <h4 className="mb-0">Loading...</h4>
                </div>
            </>
        );
    }

    if (AlbumData.isError) {
        return (
            <>
                <div className="gap2 gray-bg full_height d-flex justify-content-around align-items-center">
                    <h4 className="mb-0">Someting Went Wrong!!</h4>
                </div>
            </>
        );
    }

    return (
        <ProfileLayout>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media`} title="Images" noCount />
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/media/video`} title="Videos" noCount />
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <h3 className="fs_18 mb-3">All Picture</h3>
                <div className='row gx-4'>
                    {AllImegesList?.map((elem, index) => {
                        return (
                            <div className="col-lg-3 col-md-3 col-6" key={index}>
                                <MediaCard imageData={elem} type={"allImage"} />
                            </div>
                        );
                    })}
                </div>
            </div>
        </ProfileLayout>
    )
}

export default AllPictures
