// "transaction_type":"1" recharge own wallet
// "transaction_type":"2" send money to other
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const walletApi = createApi({
    reducerPath: "walletApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
        prepareHeaders: (headers, { getState }) => {
            const { userSlice } = getState();
            let token = userSlice.userAuthToken;
            return headers.set("x-access-token", token);
        },
    }),
    tagTypes: ["wallet"],
    endpoints: (builder) => ({
        // Reacharge own wallet(POST)
        rechargeWallet: builder.mutation({
            query: (data) => {
                console.log(data)
                return {
                    url: `api/wallet/history/create`,
                    method: "POST",
                    body: {
                        amount: data.amount,
                        description: data.description,
                        meta_type: data.meta_type,
                        meta_data: data.meta_data,
                        transaction_type: data.transaction_type
                    },
                };
            },
            invalidatesTags: ["wallet"]
        }),
        // Transfer ballence(POST)
        transferBallence: builder.mutation({
            query: (data) => {
                return {
                    url: `api/wallet/history/create`,
                    method: "POST",
                    body: {
                        amount: data.amount,
                        description: data.description,
                        meta_type: data.meta_type,
                        meta_data: data.metadata,
                        transaction_type: "2",
                        receiver_id: data.receiver_id
                    },
                };
            },
            invalidatesTags: ["wallet"]
        }),
        // wallet history(GET)
        walletHistory: builder.query({
            query: (data) => ({
                url: "api/wallet/history/list",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 10000000,
                },
            }),
            providesTags: ["wallet"],
        }),
        // wallet history(GET)
        transactionHistory: builder.query({
            query: () => ({
                url: "api/wallet/transaction-history/list",
                method: "GET",
                params: {
                    page: 1,
                    per_page: 10000000
                },
            }),
            providesTags: ["wallet"],
        }),
        // buy product from wallet
        buyProductWallet: builder.mutation({
            query: (data) => {
                return {
                    url: `api/wallet/history/create`,
                    method: "POST",
                    body: {
                        amount: data.amount,
                        description: data.description,
                        meta_type: data.meta_type,
                        meta_data: data.metadata,
                        transaction_type: "3",
                        receiver_id: data.receiver_id
                    },
                };
            },
            invalidatesTags: ["wallet"]
        }),
    })
})

export const { useRechargeWalletMutation, useWalletHistoryQuery, useTransferBallenceMutation, useTransactionHistoryQuery, useBuyProductWalletMutation } = walletApi;