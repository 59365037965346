import React, { useReducer, useState } from "react";
import style from "./CreateEvent.module.css";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { ImClock } from "react-icons/im";
import { GrLocation } from "react-icons/gr";
import AWS from "aws-sdk";
import generateRandomImageName from "../../../../methods/generateRandomImageName";
import toast from "react-hot-toast";
import { useCreateEventMutation } from "../../../../store/api/eventApi";
import { useNavigate } from "react-router-dom";
import { useGetCategoryListQuery } from "../../../../store/api/categoryMaster";
import { useSelector } from "react-redux";
import { defaultUserCover } from "../../../../components/components";
import { DatePicker } from "antd";
import { socket } from "../../../../methods/Socket";
const { RangePicker } = DatePicker;

const CreateEvent = () => {
   const navigate = useNavigate()
   // const inputRefBanner = useRef(null);
   // get user data from local storage
   const { userSliceData } = useSelector((store) => {
      return store.userSlice;
   });

   const [timing, setTiming] = useState(null)
   const [validation, setValidation] = useState(false);
   const [banner, setBanner] = useState("")
   const [createEvent] = useCreateEventMutation()
   const categoryList = useGetCategoryListQuery()
   // initial state for reducer
   const initialState = {
      title: "",
      description: "",
      venue: "",
      timing: [],
      banner: [],
      scope: "",
      category: ""
   };
   // reducer switch case
   const formReducer = (state, { type, payload }) => {
      switch (type) {
         case "SET_EVENT":
            return { ...state, [payload.name]: payload.data };
         case "SET_DURATION":
            return { ...state, timing: payload };
         case "SET_BANNER":
            return { ...state, [payload.name]: payload.data };
         default:
            return state;
      }
   };
   // reducer to manage state
   const [state, dispatch] = useReducer(formReducer, initialState);
   // common function to change state in reducer
   const handleChange = (e) => {
      dispatch({
         type: "SET_EVENT",
         payload: {
            name: e.target.name,
            data: e.target.value,
         },
      });
   };
   // function to change duration
   const handleDuration = (v, DateTime) => {
      if (DateTime.length === 2) {
         setTiming(false)
      }
      dispatch({ type: "SET_DURATION", payload: DateTime });
   };
   // to set banner for event in state
   const handleBanner = (e) => {
      setBanner(e.target.files[0])
      const selectedFIles = [];
      const targetFiles = e.target.files;
      const targetFilesObject = [...targetFiles];
      targetFilesObject.map((file) => {
         return selectedFIles.push(URL.createObjectURL(file));
      });
      if (selectedFIles.length > 0) {
         dispatch({
            type: "SET_BANNER",
            payload: {
               name: e.target.name,
               data: [...selectedFIles],
            },
         });
      }
   };

   // function call to create event
   const submitForm = async (e) => {
      e.preventDefault();
      if (state.timing.length === 0) {
         setTiming(true)
      } else {
         setTiming(false)
         // do your all functions
         if (navigator.onLine) {
            if (banner.name) {
               // for cover picture start
               const bannerImage_S3_BUCKET = "ubuntu-social-media-2";
               const bannerImage_REGION = "ap-south-1";
               AWS.config.update({
                  accessKeyId: "AKIA4NNCEDROYLRYR2OX",
                  secretAccessKey: "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",
               });
               const bannerImage_s3 = new AWS.S3({
                  params: { Bucket: bannerImage_S3_BUCKET },
                  region: bannerImage_REGION,
               });
               let randomNameBannerImage = generateRandomImageName(banner.name);
               let bannerName = "page_" + randomNameBannerImage.split(" ").join("_").split("-").join("_");
               let bannerSize = banner.size;
               let bannerType = banner.type;

               const bannerImage_s3params = {
                  Bucket: bannerImage_S3_BUCKET,
                  Key: bannerName,
                  Body: banner,
               };

               var only_bannerImage_upload = bannerImage_s3.putObject(bannerImage_s3params).on("httpUploadProgress", (evt) => {
                  // File uploading progress
                  console.log(
                     "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
                  );
               }).promise();

               await only_bannerImage_upload.then((data, err) => { });

               let bannerImageArr = []

               bannerImageArr.push({
                  image_url: ("https://ubuntu-social-media-2.s3.ap-south-1.amazonaws.com/" + bannerName),
                  size: bannerSize,
                  mime_type: bannerType,
               });

               let obj = {
                  scope: state.scope,
                  title: state.title,
                  description: state.description,
                  venue: state.venue,
                  banner_image: bannerImageArr,
                  timing: state.timing,
                  category_id: state.category
               }

               createEvent(obj).then((res) => {
                  if (res?.data?.error === 0) {
                     toast.success(res?.data?.message)
                     navigate(`/profile/${userSliceData._id}/events`);
                     socket.emit("sendNotificationDataOthers", {
                        sender_id: userSliceData._id,
                        meta_data: res.data?.data?._id,
                        meta_type: "Group",
                        notification_for: "Create",
                     });
                  } else {
                     toast.error("Something went wrong !!!")
                  }
               })
            } else {
               let obj = {
                  scope: state.scope,
                  title: state.title,
                  description: state.description,
                  venue: state.venue,
                  banner_image: [],
                  timing: state.timing,
                  category_id: state.category
               }
               createEvent(obj).then((res) => {
                  if (res?.data?.error === 0) {
                     toast.success(res?.data?.message)
                     navigate(`/profile/${userSliceData._id}/events`);
                     socket.emit("sendNotificationDataOthers", {
                        sender_id: userSliceData._id,
                        meta_data: res.data?.data?._id,
                        meta_type: "Group",
                        notification_for: "Create",
                     });
                  } else {
                     toast.error("Something went wrong !!!")
                  }
               })
            }
         }
      }
      setValidation(true);
   };
   console.log(state)
   return (
      <div className="gap2 gray-bg full_height">
         <div className="container">
            <div className="row">
               <div className={"col-md-7 col-lg-8"}>
                  <Card className={`${style.central_meta}`}>
                     <Card.Body className={`${style.card_body}`}>
                        <Card.Title className={`${style.card_title} mb-3 fs-4`}>
                           Create Your Event
                        </Card.Title>
                        <Form
                           noValidate
                           validated={validation}
                           method="post"
                           onSubmit={submitForm}
                        >
                           <Row>
                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                 <Form.Label>Event name*</Form.Label>
                                 <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter your event name"
                                    value={state.title}
                                    onChange={handleChange}
                                    name="title"
                                 />
                                 {/* <Form.Control.Feedback type="invalid">Please provide event name</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                 <Form.Label>Event scope*</Form.Label>
                                 <Form.Select required aria-label="Default select example" value={state.scope} name="scope" onChange={handleChange}>
                                    <option value="">Select scope</option>
                                    <option value="public">Public</option>
                                    <option value="private">Private</option>
                                 </Form.Select>
                                 {/* <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                 <Form.Label>Event category*</Form.Label>
                                 <Form.Select required aria-label="Default select example" value={state.category} name="category" onChange={handleChange}>
                                    <option value="">Select category</option>
                                    {categoryList?.data?.data.map((el) => (
                                       <option key={el._id} value={el._id}>{el.category_name}</option>
                                    ))}
                                 </Form.Select>
                                 {/* <Form.Control.Feedback type="invalid">Please provide page scope</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom02" className={`col-md-6 mb-3`}>
                                 <Form.Label>Event location*</Form.Label>
                                 <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter your event location"
                                    value={state.venue}
                                    onChange={handleChange}
                                    name="venue"
                                 />
                                 {/* <Form.Control.Feedback type="invalid">Please add location</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom02" className={`col-md-12 mb-3`}>
                                 <Form.Label>Description*</Form.Label>
                                 <Form.Control
                                    placeholder="Enter a small description"
                                    required
                                    type="text"
                                    value={state.description}
                                    onChange={handleChange}
                                    name="description"
                                    as="textarea"
                                    style={{ height: '70px' }}
                                 />
                                 {/* <Form.Control.Feedback type="invalid">Please add description</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="validationCustom01" className={`col-md-6 mb-3`}>
                                 <Form.Label>Event duration*</Form.Label>
                                 <RangePicker
                                    showTime
                                    onChange={handleDuration}
                                    style={{ width: '100%', height: '39px', borderColor: timing === null ? "var(--bs-border-color)" : (timing === true ? "var(--red)" : "var(--bs-form-valid-border-color)") }}
                                    required
                                 />
                                 {/* <Form.Control.Feedback className={style.invalidMessage} style={{ display: timing ? "block" : "none" }}>Please provide event date and time</Form.Control.Feedback> */}
                              </Form.Group>

                              <Form.Group as={Col} controlId="formFile" className={`col-md-6 mb-3`}>
                                 <Form.Label>Upload banner*</Form.Label>
                                 <Form.Control className="prevent-validation" type="file" onChange={handleBanner} name="banner" />
                                 {/* <Form.Control.Feedback type="invalid">Please select event photo</Form.Control.Feedback> */}
                              </Form.Group>
                           </Row>
                           <Col className='d-flex align-items-center justify-content-center'>
                              <Button className="btn btn-primary" type="submit">Create Event</Button>
                           </Col>
                        </Form>
                     </Card.Body>
                  </Card>
               </div>
               <div className="col-md-5 col-lg-4">
                  <div className="eventCard">
                     <figure>
                        <img
                           src={
                              state.banner.length > 0
                                 ? state.banner
                                 : defaultUserCover
                           }
                           alt="..."
                           height={"300"}
                        />
                        <em>10k Participated</em>
                     </figure>
                     <div className="eventCardContent">
                        <span className="upload_time">
                           <ImClock />{" "}
                           {state.timing.length > 0
                              ? state.timing[0] + " to " + state.timing[1]
                              : "Event Duration"}
                        </span>
                        <h6>
                           {state.title.length > 0
                              ? state.title
                              : "Event Title"}
                        </h6>
                        {
                           state.description !== '' ?
                              <>
                                 <p>
                                    {state.description}
                                 </p>
                              </>
                              :
                              "Description"
                        }

                        <div className="location">
                           <GrLocation />
                           {state.venue.length > 0
                              ? state.venue
                              : "Event Location"}
                        </div>
                        <button className="btn btn-light w-100">
                           Join Event
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default CreateEvent;
