import React, { useEffect, useState } from 'react'
import { DashboardMenuLink, FilterWrapper, GroupCard } from '../../../../components/components'
import { CreateWidget } from '../../../../components/dashboard/Dashboard';
import { Button } from 'react-bootstrap';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Dropdown, Pagination } from 'antd';
import { useJoinedGroupsQuery, useMyGroupsQuery, useSuggestedGroupsQuery, useGetPendingInvitationListQuery, useAllCountGroupListsQuery, useAllOtherGroupCountListsQuery } from '../../../../store/api/groupApi';
import ProfileLayout from '../../ProfileLayout';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetCreatedGroupByIdQuery, useGetJoinedGroupByIdQuery } from '../../../../store/api/groupApi';

const JoinedGroups = () => {
    const { userId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const [pageNo, setPageNo] = useState(searchParams.get("pageNo") || 1)
    const [search, setSearch] = useState(searchParams.get("search") || "")
    const [sort, setSort] = useState(searchParams.get("sort") || "see_all")
    let countData = useAllCountGroupListsQuery()
    let otherCountData = useAllOtherGroupCountListsQuery(userId)

    const paginationPageChange = (page) => {
        setPageNo(page);
    }

    const infoObj = {
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }

    //all groups data in which I have joined
    const joinedAllGroups = useJoinedGroupsQuery(infoObj);
    const joinedAllGroupsData = joinedAllGroups?.data?.data;

    useEffect(() => {
        let params = { pageNo };
        if (search) {
            params.search = search;
        }
        if (sort) {
            params.sort = sort;
        }

        setSearchParams(params);
    }, [pageNo, search, sort]);

    const { userSliceData } = useSelector((store) => {
        return store.userSlice;
    });

    // other user
    const otherUserParam = {
        id: userId,
        pageNo: pageNo,
        searchVal: search,
        sortVal: sort
    }
    const otherUserJoinedGroup = useGetJoinedGroupByIdQuery(otherUserParam)

    return (
        <ProfileLayout>
            <FilterWrapper>
                <div className="row align-items-center">
                    <div className="col-lg-7">
                        <div className="DashboardMenuLinks">
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/groups`} title="Groups" count={userId === userSliceData._id ? countData.isSuccess ? countData.data.data.total_groups : 0 : otherCountData?.isSuccess ? otherCountData?.data?.data?.total_groups : 0} />
                            <DashboardMenuLink url={`/profile/${userId === userSliceData._id ? userSliceData._id : userId}/groups/joined`} title="Joined Groups" count={userId === userSliceData._id ? countData.isSuccess ? countData.data.data.total_joined_groups : 0 : otherCountData?.isSuccess ? otherCountData?.data?.data?.total_joined_groups : 0} />
                            {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/groups/pending`} title="Pending Groups" count={countData.isSuccess ? countData.data.data.total_pending_groups : 0} />}
                            {userId === userSliceData._id && <DashboardMenuLink url={`/profile/${userSliceData._id}/groups/suggested`} title="Suggested Groups" count={countData.isSuccess ? countData.data.data.total_suggested_groups : 0} />}
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="gx-2 justify-content-md-end row">
                            <div className="col-lg-7 col-md-7 col-sm-7">
                                <form method="post">
                                    <input
                                        type="text"
                                        placeholder="Search.."
                                        className="form-control"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={searchParams.get("search") || search}
                                    />
                                </form>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="select-options">
                                    <select className="form-select" onChange={(e) => setSort(e.target.value)} value={searchParams.get("sort") || sort}>
                                        <option value={"see_all"}>See All</option>
                                        <option value={"a_to_z"}>A to Z</option>
                                        <option value={"z_to_a"}>Z to A</option>
                                        <option value={"newest"}>Newest</option>
                                        <option value={"oldest"}>Oldest</option>
                                    </select>
                                </div>
                            </div>
                            {/* <div className="col-lg-1 col-md-1 col-sm-1">
                                <Dropdown
                                    menu={{ items }}
                                    placement="bottomRight"
                                    trigger={["click"]}
                                >
                                    <Button className="filterWrapDropBtn">
                                        <BsThreeDotsVertical />
                                    </Button>
                                </Dropdown>
                            </div> */}
                        </div>
                    </div>
                </div>
            </FilterWrapper>
            <div className='central-meta'>
                <div className="row gx-4">
                    {
                        (joinedAllGroups.isLoading && otherUserJoinedGroup.isLoading) && (
                            <div className="col-12">
                                <h5 className="text-center mb-0 py-5 fs_18">Loading...</h5>
                            </div>
                        )
                    }
                    {
                        (joinedAllGroups.isError && otherUserJoinedGroup.isError) && (
                            <div className="col-12">
                                <h5 className="text-center mb-0 py-5 fs_18">Someting Went Wrong !!</h5>
                            </div>
                        )
                    }

                    {/* <h3 className="fs_18 mb-3">Joined groups</h3> */}
                    {userId === userSliceData._id ? joinedAllGroupsData?.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-3 col-6" key={index}>
                                <GroupCard data={elem} groupId={elem._id} type={"joinedGroup"} />
                            </div>
                        );
                    }) : otherUserJoinedGroup?.data?.data?.map((elem, index) => {
                        return (
                            <div className="col-lg-2 col-md-3 col-6" key={index}>
                                <GroupCard data={elem} groupId={elem._id} type={"others"} />
                            </div>
                        );
                    })}
                    {userId === userSliceData._id ? joinedAllGroupsData?.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Joined Group !!!</h5>
                    </div> : otherUserJoinedGroup?.data?.data.length === 0 && <div className="col-12">
                        <h5 className="text-center mb-0 py-5 fs_18">No Joined Group !!!</h5>
                    </div>}
                </div>
                {/* pagination component */}
                <div>
                    {userId === userSliceData._id ? (
                        joinedAllGroupsData?.data?.total_count > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={pageNo} total={joinedAllGroupsData?.data?.total_count} onChange={paginationPageChange} defaultPageSize={12} />
                    ) : (
                        otherUserJoinedGroup?.data?.total_count > 12 && <Pagination className="mx-auto mt-4" style={{ width: "fit-content" }} defaultCurrent={pageNo} total={otherUserJoinedGroup?.data?.total_count} onChange={paginationPageChange} defaultPageSize={12} />
                    )}
                </div>
            </div>
        </ProfileLayout>
    )
}

export default JoinedGroups